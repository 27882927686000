/*----------------------------------------*/
/*  06. TITLE CSS START
/*----------------------------------------*/


.sec {
  &-title {
    font-size: 48px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    color: var(--black-3);
    padding-bottom: 25px;

    @media #{$xl} {
      font-size: 45px;
    }

    @media #{$lg} {
      font-size: 40px;
    }

    @media #{$md} {
      font-size: 32px;
    }

    @media #{$sm} {
      font-size: 30px;
    }
  }
  &-counter {
    font-size: 48px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    color: var(--white);
    padding-bottom: 65px;
    text-align: center;
  }


  &-subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: var(--primary);
    margin-bottom: 15px;
    position: relative;

    span {
      font-size: 48px;
      line-height: 1;
      font-weight: 600;
      -webkit-text-stroke: 2px var(--primary);
      color: transparent;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-52%);
      opacity: 0.1;

      @media #{$sm} {
        font-size: 36px;
      }
    }

    &-2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: 1.8px;
      color: var(--yellow-2);
      text-transform: uppercase;
      text-decoration-line: underline;
      padding-bottom: 15px;
    }
  }
}