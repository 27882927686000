.newsletter {
  &__area {
    background-color: var(--primary);
    position: relative;

    .sec-subtitle {
      margin-bottom: 25px;
    }
  }

  &__left {
    position: absolute;
    bottom: 0;

    @media #{$md} {
      display: none;
    }

    img {
      @media #{$lg} {
        max-width: 400px;
      }
    }
  }

  &__right {
    padding-left: 70px;

    @media #{$md} {
      padding-left: 0;
    }

    .sec-subtitle {
      color: var(--black-2);

      span {
        -webkit-text-stroke: 2px var(--black-2);
      }
    }

    form {
      position: relative;
      margin-top: 20px;

      @media #{$sm} {
        margin-top: 15px;
      }
    }

    input[type="email"] {
      font-size: 20px;
      padding: 20px 25px;
      border: none;
      outline: none;
      width: 100%;
      font-weight: 600;
      color: var(--gray-3);
      padding-right: 200px;
      @media #{$lg} {
        padding-right: 50px;
      }
      @media #{$md} {
        padding-right: 200px;
      }
      @media #{$sm} {
        font-size: 18px;
        padding: 20px;
      }

      &::placeholder {
        color: var(--gray-3);
        opacity: 0.5;
      }
    }

    .submit {
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      background-color: var(--black-2);
      padding: 20px 30px;
      border: none;
      outline: none;
      color: var(--white);
      letter-spacing: 1.6px;

      @media #{$sm} {
        padding: 18px 15px;
        position: relative;
        top: 5px;
      }
    }
  }
}