.pt-100 {
  padding-top: 100px;

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$sm} {
    padding-top: 50px;
  }
}

.pt-120 {
  padding-top: 120px;

  @media #{$lg} {
    padding-top: 100px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$sm} {
    padding-top: 50px;
  }
}

.pt-125 {
  padding-top: 125px;

  @media #{$lg} {
    padding-top: 100px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$sm} {
    padding-top: 50px;
  }
}

.pt-130 {
  padding-top: 130px;

  @media #{$lg} {
    padding-top: 100px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$sm} {
    padding-top: 50px;
  }
}

.pt-140 {
  padding-top: 140px;

  @media #{$lg} {
    padding-top: 100px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$sm} {
    padding-top: 50px;
  }
}

.pt-150 {
  padding-top: 150px;

  @media #{$lg} {
    padding-top: 100px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$sm} {
    padding-top: 50px;
  }
}

.pt-170 {
  padding-top: 170px;

  @media #{$lg} {
    padding-top: 100px;
  }

  @media #{$md} {
    padding-top: 80px;
  }

  @media #{$sm} {
    padding-top: 50px;
  }
}

// Padding Bottom
.pb-100 {
  padding-bottom: 100px;

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 50px;
  }
}

.pb-110 {
  padding-bottom: 110px;

  @media #{$lg} {
    padding-bottom: 100px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 50px;
  }
}

.pb-120 {
  padding-bottom: 120px;

  @media #{$lg} {
    padding-bottom: 100px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 50px;
  }
}

.pb-125 {
  padding-bottom: 125px;

  @media #{$lg} {
    padding-bottom: 100px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 50px;
  }
}

.pb-130 {
  padding-bottom: 130px;

  @media #{$lg} {
    padding-bottom: 100px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 50px;
  }
}

.pb-140 {
  padding-bottom: 140px;

  @media #{$lg} {
    padding-bottom: 100px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 50px;
  }
}

.pb-150 {
  padding-bottom: 150px;

  @media #{$lg} {
    padding-bottom: 100px;
  }

  @media #{$md} {
    padding-bottom: 80px;
  }

  @media #{$sm} {
    padding-bottom: 50px;
  }
}


// Margin top
.mt-130 {
  margin-top: 130px;

  @media #{$lg} {
    margin-top: 100px;
  }

  @media #{$md} {
    margin-top: 80px;
  }

  @media #{$sm} {
    margin-top: 50px;
  }
}

// 
.plr-50 {
  padding-left: 50px;
  padding-right: 50px;

  @media #{$md} {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media #{$sm} {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.plr-100 {
  padding-left: 100px;
  padding-right: 100px;

  @media #{$lg} {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media #{$md} {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media #{$sm} {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.plr-90 {
  padding-left: 90px;
  padding-right: 90px;

  @media #{$lg} {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media #{$md} {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media #{$sm} {
    padding-left: 15px;
    padding-right: 15px;
  }
}