.industries {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 6px;
    background-color: var(--secondary);
    color: var(--white);
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;

    &:hover {
      background-color: var(--primary);
    }

    &.active {
      background-color: var(--primary);
    }
  }

  &__content {
    max-width: 800px;
    padding: 20px;
    background-color: var(--light-gray);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    white-space: pre-wrap;

    p {
      color: var(--black);
    }
  }
}

// Define color variables

